import * as React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { MainPicture } from '../../components/MainPicture';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from '../../components/layout/StyledComponents';
import styled from '@emotion/styled';
import { colors, device } from '../../components/layout/GlobalStyles';
import { Seo } from '../../components/Seo';

const StyledPayments = styled.div`
    padding: 2em 1em;
    .text {
        padding-top: 2em;
    }
    .payments {
        .apple {
            height: 40px;
            width: auto;
        }
        display: flex;
        margin: 3em auto 6.5em;
        justify-content: space-around;
        max-width: 740px;
        > div {
            height: 100%;
        }
    }
    p {
        font-size: 1em;
        line-height: 30px;
        margin-top: 1em;
    }
    h4 {
        font-size: 24px;
        margin: 2em 0em 1em 0em;
        color: ${colors.blueDark};
    }
    h5 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
    }

    .credit-applications {
        justify-content: center;
        display: flex;
        flex-direction: column;
        column-gap: 24px;
    }

    .care-credit {
        position: relative;
        background: #cce0a3;
        border-radius: 15px;
        padding: 20px;
        max-width: 350px;
        margin: 2em auto;
        display: flex;
        flex-direction: column;
        padding-right: 48px;
        width: 350px;
        height: 230px;
        .arrow {
            position: absolute;
            right: 24px;
        }
        p {
            margin: 1em 0em;
        }
        div {
            display: flex;
            align-items: center;
        }
        &.pink {
            background: #ffe8e8;
        }
    }
    .button-flex {
        button {
            margin: 3em 0em;
        }
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
    @media ${device.tablet} {
        .text {
            padding-top: 4em;
            margin: 0 auto;
            max-width: 700px;
        }

        .button-flex {
            button {
                margin: 2em 0em;
            }
        }
        .credit-applications {
            flex-direction: row;
        }
    }
    @media ${device.laptopL} {
        button {
            margin: 2em 0em;
        }
        .text {
            margin: 0 auto;
            max-width: 846px;
        }
        padding: 3em 0em;

        h5 {
            margin: 2em 0em 1.4em;
        }
    }
    @media ${device.desktopL} {
        .text {
            margin: 0 auto;
            max-width: 1265px;
        }

        h4 {
            font-size: 24px;
            color: ${colors.blueDark};
        }
        button {
            margin: 2.5em 0em;
        }
        h5 {
            padding: 0em 1.6em;
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
        }
        p {
            padding: 0em 2em;
            line-height: 32px;
            font-size: 1.2em;
        }
    }
    @media ${device.tablet} {
        padding: 0em 0em 2em;
    }
`;

const Payments: Page = () => (
    <DefaultLayout currentPage="Payments">
        <Seo
            title="Payments & Financials"
            description="payment plans and finance options with Magic Smiles dental care clinic in Linden New Jersey"
        />
        <StyledPayments>
            <MainPicture title="Payments">
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    placeholder="none"
                    className="underlayer"
                    src="../../images/payments.jpeg"
                    alt="group of hands on a wooden table"
                />
                <StaticImage
                    imgStyle={{ borderRadius: '20px' }}
                    quality={100}
                    placeholder="none"
                    src="../../images/payments.jpeg"
                    alt="group of hands on a wooden table"
                />
            </MainPicture>
            <div className="text">
                <p>
                    We want to help you receive the dental care you deserve. We discuss all cost and
                    payment options with you to make sure you are comfortable with your treatment
                    plan and to answer any questions you have prior to the start treatment. You will
                    never have surprises when it comes to your bill.
                </p>
                <h4>Dental Insurance</h4>
                <p>
                    We want you to get the most out of your budget, so we work with your insurance
                    provider to ensure you get the best coverage available to you.
                </p>
                <p>
                    We are a Preferred Provider (PPO) for many dental insurance companies. If you
                    need assistance, or have questions about your insurance policies or claims, our
                    staff is knowledgeable and always available to help you.
                </p>
                <div className="segment">
                    <h4>Popular Payment Options</h4>
                    <p>
                        We accept cash, checks, major credit cards, have in-house financing options
                        and work with CareCredit®, so you can get the care you need when you need
                        it.
                    </p>
                </div>

                <div className="payments">
                    <StaticImage
                        placeholder="none"
                        src="../../images/svgs/credit-card.svg"
                        alt="black card"
                    />
                    <StaticImage
                        placeholder="none"
                        src="../../images/svgs/cash.svg"
                        alt="cash symbol"
                    />
                    <StaticImage
                        className="apple"
                        placeholder="none"
                        src="../../images/svgs/apple-pay.svg"
                        alt="apple pay logo"
                    />
                </div>
                <div className="payments">
                    <StaticImage
                        placeholder="none"
                        className="aetna"
                        src="../../images/svgs/aetna.svg"
                        alt="aetna logo"
                    />
                    <StaticImage
                        placeholder="none"
                        src="../../images/svgs/metlife.svg"
                        alt="metlife logo"
                    />
                    <StaticImage
                        placeholder="none"
                        src="../../images/svgs/delta-dental.svg"
                        alt="delta dental logo"
                    />
                </div>
                <div className="credit-applications">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://carecredit.com/go/QRM669/"
                    >
                        <div className="care-credit">
                            <p>Apply for</p>
                            <div>
                                <StaticImage
                                    className="logo"
                                    src="../../images/svgs/carecredit.svg"
                                    placeholder="none"
                                    alt="care credit logo"
                                />
                                <StaticImage
                                    className="arrow"
                                    src="../../images/svgs/arrow-right-green.svg"
                                    placeholder="none"
                                    alt="green arrow pointing right"
                                />
                            </div>

                            <p>in just a few steps</p>
                        </div>
                    </a>

                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://carecredit.com/go/QRM669/"
                    >
                        <div className="care-credit pink">
                            <p>Apply for</p>
                            <div>
                            <a
                                href=" https://go.alphaeoncredit.com/practice-application/38061"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <StaticImage
                                    placeholder="none"
                                    src="../../images/alphaeon-logo.png"
                                    alt="alphaeon credit logo"
                                />
                            </a>
                                
                                <StaticImage
                                    className="arrow"
                                    src="../../images/svgs/arrow-right-green.svg"
                                    placeholder="none"
                                    alt="green arrow pointing right"
                                />
                            </div>

                            <p>in just a few steps</p>
                        </div>
                    </a>
                </div>
                <div style={{ paddingBottom: '2em' }} className="button-flex">
                    <Button>
                        <StaticImage
                            className="blue-mobile"
                            src="../../images/svgs/mobile-white.svg"
                            placeholder="none"
                            alt="white mobile phone"
                        />
                        Call Us at (908) 486-5000
                    </Button>
                </div>
            </div>
        </StyledPayments>
    </DefaultLayout>
);

export default Payments;
